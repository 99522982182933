var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"table table-hover table-sm bg-white table-striped rounded"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"vertical-align":"middle","min-width":"100px","font-size":"large"}},[_vm._v(" Operación ")]),_c('th',{staticClass:"text-center",staticStyle:{"vertical-align":"middle","min-width":"90px","font-size":"large"}},[_vm._v(" Múltiple ")]),_vm._l((_vm.$parent.total_dias),function(fecha,index){return _c('th',{key:index,staticClass:"text-center"},[_c('div',{staticClass:"bg-navy rounded-top",staticStyle:{"font-size":"11px"}},[_vm._v(" DD/MM/YY ")]),_c('div',{staticClass:"bg-white rounded-bottom border border-info"},[_vm._v(" "+_vm._s(fecha)+" ")])])})],2)]),_c('tbody',[_c('tr',[_vm._m(0),_c('td',{staticClass:"text-center"},[(_vm.loanding.crudo)?_c('button',{staticClass:"btn btn-success shadow mt-2",attrs:{"type":"button","disabled":""}},[_c('span',{staticClass:"spinner-grow spinner-grow-sm",attrs:{"role":"status","aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]):_c('div',{staticClass:"d-flex justify-content-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.crudo),expression:"crudo"}],staticClass:"form-control form-control-sm col-md-6 text-center text-bold p-0 shadow-sm",class:_vm.tipoInput == 'crudo' && _vm.multiple && _vm.$parent.check_active
                ? 'bg-white shadow border border-success shadow-lg'
                : 'alert-default-success border border-success',staticStyle:{"min-width":"40px"},attrs:{"disabled":_vm.$parent.bloqueado,"type":"text"},domProps:{"value":(_vm.crudo)},on:{"focus":function($event){return _vm.focusMultiple('crudo')},"change":_vm.asiganacionmultiple,"keydown":_vm.validarNumeros,"input":function($event){if($event.target.composing)return;_vm.crudo=$event.target.value}}})]),(_vm.loanding.planta)?_c('button',{staticClass:"btn btn-success shadow mt-2",attrs:{"type":"button","disabled":""}},[_c('span',{staticClass:"spinner-grow spinner-grow-sm",attrs:{"role":"status","aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]):_c('div',{staticClass:"d-flex justify-content-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.planta),expression:"planta"}],staticClass:"form-control form-control-sm col-md-6 text-center mt-2 text-bold p-0 shadow-sm",class:_vm.tipoInput == 'planta' && _vm.multiple && _vm.$parent.check_active
                ? 'bg-white shadow border border-success shadow-lg'
                : 'alert-default-success border border-success',staticStyle:{"min-width":"40px"},attrs:{"disabled":_vm.$parent.bloqueado,"type":"text"},domProps:{"value":(_vm.planta)},on:{"focus":function($event){return _vm.focusMultiple('planta')},"change":_vm.asiganacionmultiple,"keydown":_vm.validarNumeros,"input":function($event){if($event.target.composing)return;_vm.planta=$event.target.value}}})]),(_vm.loanding.regalias)?_c('button',{staticClass:"btn btn-success shadow mt-2",attrs:{"type":"button","disabled":""}},[_c('span',{staticClass:"spinner-grow spinner-grow-sm",attrs:{"role":"status","aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]):_c('div',{staticClass:"d-flex justify-content-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.regalias),expression:"regalias"}],staticClass:"form-control form-control-sm col-md-6 text-center mt-2 text-bold p-0 shadow-sm",class:_vm.tipoInput == 'regalias' && _vm.multiple && _vm.$parent.check_active
                ? 'bg-white shadow border border-success shadow-lg'
                : 'alert-default-success border border-success',staticStyle:{"min-width":"40px"},attrs:{"disabled":_vm.$parent.bloqueado,"type":"text"},domProps:{"value":(_vm.regalias)},on:{"focus":function($event){return _vm.focusMultiple('regalias')},"change":_vm.asiganacionmultiple,"keydown":_vm.validarNumeros,"input":function($event){if($event.target.composing)return;_vm.regalias=$event.target.value}}})]),(_vm.loanding.inventario)?_c('button',{staticClass:"btn btn-success shadow mt-2",attrs:{"type":"button","disabled":""}},[_c('span',{staticClass:"spinner-grow spinner-grow-sm",attrs:{"role":"status","aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]):_c('div',{staticClass:"d-flex justify-content-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inventario),expression:"inventario"}],staticClass:"form-control form-control-sm col-md-6 text-center mt-2 text-bold p-0 shadow-sm",class:_vm.tipoInput == 'inventario' && _vm.multiple && _vm.$parent.check_active
                ? 'bg-white shadow border border-success'
                : 'alert-default-success border border-success',staticStyle:{"min-width":"40px"},attrs:{"disabled":_vm.$parent.bloqueado,"type":"text"},domProps:{"value":(_vm.inventario)},on:{"focus":function($event){return _vm.focusMultiple('inventario')},"change":_vm.asiganacionmultiple,"keydown":_vm.validarNumeros,"input":function($event){if($event.target.composing)return;_vm.inventario=$event.target.value}}})])]),_vm._l((_vm.$parent.operacionDetalles.filter(
          (detalle) => {
            return detalle.producto_liquido_id == _vm.producto.id;
          }
        )),function(detalle,index){return _c('td',{key:index + 1,staticClass:"text-center"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(detalle.crudo_ventas),expression:"detalle.crudo_ventas"}],staticClass:"form-control form-control-sm col-md-6 text-center text-bold p-0 shadow-sm",class:_vm.indexFocus == index + 1 &&
              _vm.tipoInput == 'crudo' &&
              _vm.multiple == false &&
              _vm.$parent.check_active
                ? 'bg-white shadow border border-warning'
                : 'alert-default-warning border border-warning',staticStyle:{"min-width":"40px"},attrs:{"disabled":_vm.$parent.bloqueado,"type":"text"},domProps:{"value":(detalle.crudo_ventas)},on:{"focus":function($event){return _vm.focus(index + 1, _vm.producto.id, 'crudo')},"keydown":_vm.validarNumeros,"input":function($event){if($event.target.composing)return;_vm.$set(detalle, "crudo_ventas", $event.target.value)}}})]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(detalle.planta_externa),expression:"detalle.planta_externa"}],staticClass:"form-control form-control-sm col-md-6 text-center mt-2 text-bold p-0 shadow-sm",class:_vm.indexFocus == index + 1 &&
              _vm.tipoInput == 'planta' &&
              _vm.multiple == false &&
              _vm.$parent.check_active
                ? 'bg-white shadow border border-warning'
                : 'alert-default-warning border border-warning',staticStyle:{"min-width":"40px"},attrs:{"type":"text","disabled":_vm.$parent.bloqueado},domProps:{"value":(detalle.planta_externa)},on:{"focus":function($event){return _vm.focus(index + 1, _vm.producto.id, 'planta')},"keydown":_vm.validarNumeros,"input":function($event){if($event.target.composing)return;_vm.$set(detalle, "planta_externa", $event.target.value)}}})]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(detalle.regalias),expression:"detalle.regalias"}],staticClass:"form-control form-control-sm col-md-6 text-center mt-2 text-bold p-0 shadow-sm",class:_vm.indexFocus == index + 1 &&
              _vm.tipoInput == 'regalias' &&
              _vm.multiple == false &&
              _vm.$parent.check_active
                ? 'bg-white shadow border border-warning'
                : 'alert-default-warning border border-warning',staticStyle:{"min-width":"40px"},attrs:{"type":"text","disabled":_vm.$parent.bloqueado},domProps:{"value":(detalle.regalias)},on:{"focus":function($event){return _vm.focus(index + 1, _vm.producto.id, 'regalias')},"keydown":_vm.validarNumeros,"input":function($event){if($event.target.composing)return;_vm.$set(detalle, "regalias", $event.target.value)}}})]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(detalle.inventario),expression:"detalle.inventario"}],staticClass:"form-control form-control-sm col-md-6 text-center mt-2 text-bold p-0 shadow-sm",class:_vm.indexFocus == index + 1 &&
              _vm.tipoInput == 'inventario' &&
              _vm.multiple == false &&
              _vm.$parent.check_active
                ? 'bg-white shadow border border-warning'
                : 'alert-default-warning border border-warning',staticStyle:{"min-width":"40px"},attrs:{"type":"text","disabled":_vm.$parent.bloqueado},domProps:{"value":(detalle.inventario)},on:{"focus":function($event){return _vm.focus(index + 1, _vm.producto.id, 'inventario')},"keydown":_vm.validarNumeros,"input":function($event){if($event.target.composing)return;_vm.$set(detalle, "inventario", $event.target.value)}}})])])})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-bold mr-2"},[_c('div',{staticClass:"mb-3 mt-2"},[_vm._v("Crudo")]),_c('div',{staticClass:"mb-3"},[_vm._v("Planta externa")]),_c('div',{staticClass:"mb-3"},[_vm._v("Regalías")]),_c('div',[_vm._v("Invetarios")])])
}]

export { render, staticRenderFns }